import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import axios from 'axios'

import './assets/css/style.css'
import './assets/iconfont/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css'

// 封装的axios
import http from './utils/http'

// 使用MD5加密
import md5 from 'js-md5'


Vue.config.productionTip = false
Vue.prototype.$axios = axios

Vue.prototype.$http = http

Vue.prototype.$md5 = md5

// 兄弟组件直接通信
Vue.prototype.bus = new Vue();


Vue.use(ElementUI);

// 将所有未登录的通话从定向到 /login
/**
 * beforeEach 规定进入路由需要权限
 * to: Route 即将进入的目标 路由对象
 * from: Route 当前导航正要离开的路由
 * netx: Function 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
 * 
 */
router.beforeEach((to, from, next) => {
  // 判断路由 当路由=== /login 时 删除缓存中的会话信息
  if (to.path === '/login') {
    localStorage.removeItem('usertoken');
  }
  // 当路由 !== /login 时 将会话信息中的信息取出
  var user = localStorage.getItem('usertoken');
  // 判断会话信息为空 并且 路由!== /login 时 则执行next值跳转到登录页
  if (!user && to.path !== '/login') {
    next({
      path: '/login'
    })
  } else {
    // 否则不做操作
    next();
  }
})


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
