// 导入axios
import axios from 'axios'

const service = axios.create({
  baseURL: 'http://tf.guwen666.com/crm/api',
  // baseURL: 'http://localhost:8888/crm/api',
  // baseURL: 'http://192.168.0.123/crm/api',
  timeout: 20000
})

service.interceptors.request.use(config => {
  // console.log('取值：', JSON.parse(localStorage.getItem('user')));
  let token = JSON.parse(localStorage.getItem('usertoken'));
  config.headers = {
    "Content-Type": "application/json; charset=utf-8",
  }
  config.headers["X-Access-Token"] = token
  // console.log(config);
  return config;

}, error => {
  console.log(error);
  Promise.reject(error)
})

const http = {
  /**
   * url  请求地址
   * query  url参数
   * psot  请求数据
   * methods  请求方法
   * defaultReq 默认值
   */

  submit(req) {
    // 方法的默认值
    const defaultReq = {
      url: '/',
      query: {},
      post: {},
      method: 'POST'
    }
    // 合并两个对象，后边覆盖前边
    let r = {
      ...defaultReq,
      ...req
    };

    return service(r)
  }
}

// 导出
export default http