// 系统登录页
<template>
  <div class="login-container">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="0px" class="demo-ruleForm">
      <h3 class="title">CRM系统</h3>
      <el-form-item prop="username">
        <el-input type="text" v-model="ruleForm.username" auto-complete="off" placeholder="用户名" prefix-icon="iconfont icon-yonghu-fuben"></el-input>
      </el-form-item>

      <el-form-item prop="password">
        <el-input :type="is ? 'password' : 'text'" v-model="ruleForm.password" auto-complete="off" placeholder="密码" prefix-icon="iconfont icon-mima">
          <i slot="suffix" :class="is ? 'iconfont icon-mimayincang' : 'iconfont icon-mimaxianshi'" @click="is = !is"></i>
        </el-input>
      </el-form-item>
      <el-form-item style="width:100%;">
        <el-button type="primary" style="width:100%;" @click="submitForm">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      is: true,
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        /**
         * required 是否必填
         */
        username: [
          { required: true, message: "请输入您的用户名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入您的密码", trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    // 表单提交事件
    submitForm() {
      // 获取当前时间戳 计算从1970.1.1开始的秒数
      const timestamp = parseInt(new Date().valueOf() / 1000);
      /**
       * 登录系统 /auth/login
       */
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let login_id = this.ruleForm.username;
          let pwd_hash = this.$md5(login_id + this.ruleForm.password);
          let login_pwdhash = this.$md5(login_id + timestamp + pwd_hash);
          // console.log(login_id, timestamp, login_pwdhash)  // 登录名，登录时间戳-s，提交密码
          this.$http
            .submit({
              url: "/auth/login",
              method: "POST",
              data: {
                // 请求数据
                login_id: this.ruleForm.username,
                ts: timestamp,
                login_pwdhash: login_pwdhash,
              },
            })
            .then((res) => {
              console.log(res);
              let isuser = res.data[0]; // 判断用户登录状态 0-成功 1-账号或密码错误
              if (isuser == 0) {
                // 将用户access_token 存储
                let resdata = res.data[2];
                localStorage.setItem(
                  "usertoken",
                  JSON.stringify(resdata.access_token)
                );
                let userinfo = {
                  exp_ts: resdata.exp_ts, // 有效时间
                  ip: resdata.ip, // ip
                  login_id: resdata.login_id, // 用户登录名
                  name: resdata.name, // 用户名
                  ua: resdata.ua, // 登录ua
                  update_time: resdata.update_time, // 登录时间
                  user_id: resdata.user_id, // 用户id
                  dept_id: resdata.dept_id, // 用户部门id
                  dept_name: resdata.dept_name, // 用户部门名
                };
                localStorage.setItem("userinfo", JSON.stringify(userinfo));
                this.$router.push({ path: "/" }); // 登录成功后跳转到系统首页
              } else if (isuser == 1) {
                // 当用户名或密码错误时
                this.$message(res.data[1]);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          // 请输入用户名和密码
          return false;
        }
      });
    },
  },
};
</script>

<style>
.login-container {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #2d3a4b;
}
.login-container .el-form {
  position: relative;
  width: 450px;
  padding: 160px 35px 0;
  margin: 0 auto;
  overflow: hidden;
}

.login-container .el-form .title {
  font-size: 26px;
  color: #eee;
  margin: 0px auto 40px auto;
  text-align: center;
  font-weight: bold;
}

.login-container .el-form .el-form-item {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #454545;
}
.login-container .el-form .el-form-item .el-input {
  height: 54px;
  line-height: 54px;
}

.login-container .el-form .el-form-item .el-input .el-input__inner {
  height: 47px;
  line-height: 47px;
  padding: 12px 5px 12px 15px;
  background: transparent;
  border: 0px;
  -webkit-appearance: none;
  border-radius: 0px;
  color: #fff;
  caret-color: #fff;
  padding-left: 40px;
}

.el-input__prefix .el-input__icon {
  color: #889aa4;
  font-size: 16px;
}
.el-form-item__content .el-input__suffix {
  right: 10px;
  cursor: pointer;
}
.el-form-item__content .el-input__prefix {
  left: 10px;
}
</style>